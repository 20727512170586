body {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  
  a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
    a, a:hover {
      text-decoration: none !important; }
  
  .content {
    height: 70vh; }
  
  .footer-59391 {
    border-top: 1px solid #efefef;
    background-color: #f0a04b;
    font-family: "Poppins", sans-serif;
    padding: 1rem 0; }
    .footer-59391 .site-logo {
      color: #fff; }
      .footer-59391 .site-logo a {
        font-size: 30px;
        color: #000;
        font-weight: 900; }
    .footer-59391 .social-icons li {
      display: inline-block; }
      .footer-59391 .social-icons li a {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%; }
        .footer-59391 .social-icons li a.dr {
          background: #ff2e6e; }
        .footer-59391 .social-icons li a.be {
          background: #394cff; }
        .footer-59391 .social-icons li a.tw {
          background: #00a0fb; }
        .footer-59391 .social-icons li a.in {
          background: #c31574; }
        .footer-59391 .social-icons li a.fb {
          background: #3b579b; }
        .footer-59391 .social-icons li a.yt {
          background: #fa2614; }
        .footer-59391 .social-icons li a span {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: #fff; }
    .footer-59391 .nav-links li {
      display: inline-block; }
      .footer-59391 .nav-links li a {
        font-size: 14px;
        color: #183a1d;
        padding: 10px; }
    @media (max-width: 1199.98px) {
      .footer-59391 .nav-links.nav-left li:first-child a {
        padding-left: 0; } }
    .footer-59391 .nav-links.nav-right li:last-child a {
      padding-right: 0; }
    @media (max-width: 1199.98px) {
      .footer-59391 .nav-links.nav-right li:first-child a {
        padding-left: 0; } }
    .footer-59391 .copyright {
      border-top: 1px solid #183a1d;
      padding-top: 0px;
      text-align: center;
      color: #183a1d; }

      .text-md-right {
        text-align: right !important;
    }

    .flex-rw {
      display: flex;
      flex-flow: row wrap;
  }
  
  .footer-list-top {
    width: 33.333%;
    display: grid;
    justify-content: center;
}

.mb-5 {
  margin-bottom: 0.5rem !important;
}

.footer-59391 .site-logo a {
  font-size: 30px;
  color: #183a1d;
  font-weight: 900;
}

.bottom-links{
  font-weight: 500;
}

.footer-icons{
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  color:#fff;
}

.social-icons>li{
  margin:5px;
}

#logo-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

#footer-mail{
  padding-top:0.5rem;
  color: #39395f;
  font-weight: 500;
  display:ruby;
}