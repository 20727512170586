/*  custom bootstrap section */
.card {
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
      transition: box-shadow .25s; 
      transition: all linear 200ms;
      margin: 10px;
      background-color: #fff;
      
  }
  .card:hover {
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    transform: scale(1.02);
  transition: all linear 300ms;
  z-index: 1;
  cursor:unset;
  }

  .card-img-top {
    width: 50%;
    height:150px;
    border-top-left-radius:2px;
    border-top-right-radius:2px;
    display:block;
    overflow: hidden;
    margin: 0 auto;
    background-color: #e1eedd;    
  }
  .card-img-top img{
    width: 50%;
    height: 150px;
    object-fit:cover; 
    transition: all .25s ease;  
  } 

  .card-title{
      width:50%;
      margin:0 auto;
      display:flex;
      text-align: center;
      color: #CC5500;
      justify-content: center;
  }

  .img-container{
    background-color: #e1eedd;    
  }


  .d-flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .card-text{
    font-size: 14px;
    margin:10px;
  }

  .card > .list-group{
      border: none;
      text-align: center;
  }

  .card-body {
    padding: 0.8rem 0.8rem;  
}

  .card-link{
      text-decoration: none;
  }

 
.link-danger {
    color: #CC5500;
    display: flex;
    text-align: center;
}

.btn-success {
    background-color: #183a1d;
    border-color: #183a1d;
}

.attempt-btn:hover, .attempt-btn:focus {
  background-color: #183a1d !important;
  border-color: #183a1d;
  color:rgb(175, 167, 167);
}

.bs-popover-top{
  inset: auto auto 25px 0px !important;
}

.popover-header{
  background-color: #f0a04b;
  font-weight: 700;
}

.popover-body{
  background-color: #fdf3e7;
  color: #183a1d;
}

.card-login-btn{
  font-weight: 400;
  font-size: 1.1em;
  text-decoration: underline !important;
}

.main-card-holder{
  display: flex;
  justify-content: center;
}