@import url(https://fonts.googleapis.com/css?family=Inter);
@import url(https://fonts.googleapis.com/css?family=Inter);
.mainContainer{
    width:100%;
    /* height: 450px; */
    background-image: url(/static/media/quizbg.ca509a1f.jpg);
    background-size: cover;
    background-position: 0px -150px;
}

.bgFilter{
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    background-color: rgba(225, 238, 221, 0.85)
}

.UpperLayer{
    margin-top:2rem;
    text-align: center;
}

.DescText {
    color: #183a1d;
    font-size: 16px;
    font-weight: 400;
}

.Features{
flex: 0 0 auto;
/* width: 100%; */
margin-top: 20px;
display: flex;
flex-direction: column;
font-weight: bold;
color: #CC5500;
}

.Catbtn{
    padding: 15px;
    margin: 5px;
    border-radius: 50%;
    pointer-events: none;
    background-color: #f0a04b;
    border-color: #183a1d;
    color: #183a1d;
}


.InnerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: initial;
}

.LogoContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 150px;
    height: 100px;
    margin: 2rem;
}

.SloganText {
    font-weight: 700;
    font-size: 28px;
    color: #C50;
}
/*  custom bootstrap section */
.card {
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
      transition: box-shadow .25s; 
      transition: all linear 200ms;
      margin: 10px;
      background-color: #fff;
      
  }
  .card:hover {
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  transition: all linear 300ms;
  z-index: 1;
  cursor:unset;
  }

  .card-img-top {
    width: 50%;
    height:150px;
    border-top-left-radius:2px;
    border-top-right-radius:2px;
    display:block;
    overflow: hidden;
    margin: 0 auto;
    background-color: #e1eedd;    
  }
  .card-img-top img{
    width: 50%;
    height: 150px;
    object-fit:cover; 
    transition: all .25s ease;  
  } 

  .card-title{
      width:50%;
      margin:0 auto;
      display:flex;
      text-align: center;
      color: #CC5500;
      justify-content: center;
  }

  .img-container{
    background-color: #e1eedd;    
  }


  .d-flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .card-text{
    font-size: 14px;
    margin:10px;
  }

  .card > .list-group{
      border: none;
      text-align: center;
  }

  .card-body {
    padding: 0.8rem 0.8rem;  
}

  .card-link{
      text-decoration: none;
  }

 
.link-danger {
    color: #CC5500;
    display: flex;
    text-align: center;
}

.btn-success {
    background-color: #183a1d;
    border-color: #183a1d;
}

.attempt-btn:hover, .attempt-btn:focus {
  background-color: #183a1d !important;
  border-color: #183a1d;
  color:rgb(175, 167, 167);
}

.bs-popover-top{
  inset: auto auto 25px 0px !important;
}

.popover-header{
  background-color: #f0a04b;
  font-weight: 700;
}

.popover-body{
  background-color: #fdf3e7;
  color: #183a1d;
}

.card-login-btn{
  font-weight: 400;
  font-size: 1.1em;
  text-decoration: underline !important;
}

.main-card-holder{
  display: flex;
  justify-content: center;
}

body {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  
  a {
    transition: .3s all ease; }
    a, a:hover {
      text-decoration: none !important; }
  
  .content {
    height: 70vh; }
  
  .footer-59391 {
    border-top: 1px solid #efefef;
    background-color: #f0a04b;
    font-family: "Poppins", sans-serif;
    padding: 1rem 0; }
    .footer-59391 .site-logo {
      color: #fff; }
      .footer-59391 .site-logo a {
        font-size: 30px;
        color: #000;
        font-weight: 900; }
    .footer-59391 .social-icons li {
      display: inline-block; }
      .footer-59391 .social-icons li a {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%; }
        .footer-59391 .social-icons li a.dr {
          background: #ff2e6e; }
        .footer-59391 .social-icons li a.be {
          background: #394cff; }
        .footer-59391 .social-icons li a.tw {
          background: #00a0fb; }
        .footer-59391 .social-icons li a.in {
          background: #c31574; }
        .footer-59391 .social-icons li a.fb {
          background: #3b579b; }
        .footer-59391 .social-icons li a.yt {
          background: #fa2614; }
        .footer-59391 .social-icons li a span {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: #fff; }
    .footer-59391 .nav-links li {
      display: inline-block; }
      .footer-59391 .nav-links li a {
        font-size: 14px;
        color: #183a1d;
        padding: 10px; }
    @media (max-width: 1199.98px) {
      .footer-59391 .nav-links.nav-left li:first-child a {
        padding-left: 0; } }
    .footer-59391 .nav-links.nav-right li:last-child a {
      padding-right: 0; }
    @media (max-width: 1199.98px) {
      .footer-59391 .nav-links.nav-right li:first-child a {
        padding-left: 0; } }
    .footer-59391 .copyright {
      border-top: 1px solid #183a1d;
      padding-top: 0px;
      text-align: center;
      color: #183a1d; }

      .text-md-right {
        text-align: right !important;
    }

    .flex-rw {
      display: flex;
      flex-flow: row wrap;
  }
  
  .footer-list-top {
    width: 33.333%;
    display: grid;
    justify-content: center;
}

.mb-5 {
  margin-bottom: 0.5rem !important;
}

.footer-59391 .site-logo a {
  font-size: 30px;
  color: #183a1d;
  font-weight: 900;
}

.bottom-links{
  font-weight: 500;
}

.footer-icons{
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  color:#fff;
}

.social-icons>li{
  margin:5px;
}

#logo-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

#footer-mail{
  padding-top:0.5rem;
  color: #39395f;
  font-weight: 500;
  display:ruby;
}
/* custom bootstrap section */

.navbar{
    background-color: #183a1d !important;
    padding-top: 0;
}

.navbar-light .navbar-nav .nav-link {
    color: #e1eedd;
}

.navbar-light .navbar-brand {
    color: #e1eedd;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #f0a04b;
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: #f0a04b;
}

.login-btn{
    margin-right: 20px;
    text-decoration: none;
    background: none;
    border:none;
    font-weight: 400;
    font-size: 1.2em;
    color:#CC5500;
}

.login-btn:hover{
    
    margin-right: 20px;
    text-decoration: none;
    background: none;
    border:none;
    font-weight: 400;
    font-size: 1.2em;
    color:#ff791a;
    transition: 200ms linear; 
}

.signup-btn{
    background: #CC5500;
    border:none;
    color:#e1eedd;
    padding:5px;
    font-weight: 400;
    font-size: 1.2em;
}

.signup-btn:hover {
    background: #CC5500;
    border:none;
    color:#b4d4aa;
    padding:5px;
    font-weight: 400;
    font-size: 1.2em;
    transition: 200ms linear;
    box-shadow: none;
}

.username{
    padding-left: .5rem;
    margin-right: 1rem;
    color:#CC5500;
   
    font-weight: 700;
}

.wctext{
    color:#CC5500;
    
}

.numberCircle {
    border-radius: 50%;
    width: 35px;
    height: 30px;
    padding: 2px;
    background: #e1eedd;
    border: 2px solid #666;
    color: #C50;
    text-align: center;
    font-weight: 700;
    display: table;
    margin-top: 0.1rem;
}

.dropdown-menu{
    background: #CC5500;
    color:#fff;
}

.dropdown-item{
    color:#e1eedd;
}

.dropdown-item:hover, .dropdown-item:focus, .dropdown-item:active{
    color:#cbd1ca;
    background-color: #CC5500 !important;
}

.nav-item{
    margin-right: 1rem;
}

.welcome-div{
    display: flex;
    margin-top:0.5rem;
}

#navbarDropdownMenuLink{
    display: flex;
}

.navbar-brand {
    padding-top: 1rem;
  }

  .navbar-light .navbar-toggler {
    color: #183a1d !important;
    border-color: none;
  }

  /* .navbar{
    font-size: 30px;
    font-size: 2.5vw !important;
  } */

  .navbar-nav{
    margin-top: 0.4rem;
  }



.flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/*  custom bootstrap section */
.catcard {
    box-shadow: rgba(225, 238, 221, 1) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    margin: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    padding:0.5rem;  
}
.catcard:hover {
cursor:unset;
}

.subcat-btn{  
width: 50%;
margin: 0 auto;
display: flex;
text-align: center;
justify-content: center;
margin-top:10px;  
}

.subcatcard-text{
  margin-top:10px !important;
}

.subcat-desc{
    width: 100%;
    max-width: 1320px;
    /* padding:2rem; */
    margin-top:1.5rem;
}

.subcat-heading{
    color: #183a1d; 
}

.subcat-text{
    box-shadow: rgba(10, 32, 18, 0.4) 0px 0px 0px 2px, rgba(11, 46, 20, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    margin-top:1rem;
}

/* === HEADING STYLE #1 === */
.one h1 {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
  }
  .one h1:before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: #b80000;
  }
  .one h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 8px;
    left: 50%;
    margin-left: -50px;
    background-color: #b80000;
  }
  

  /* === BASE HEADING === */ 

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 30px;
    color: #080808;
    transition: all 0.4s ease 0s;
  }
  
  h1 span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
  }
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
.test-heading{
    padding: 10px;
    margin: 0 auto;
    display: flex;
    
}

@media only screen and (max-device-width: 480px) {
    .heading-container{
        border-bottom: 1px solid #183a1d;
    }
}

.mark-time-container{
    display: flex;
   
}

@media only screen and (max-device-width: 480px) {
    .mark-time-container{
        padding: 5px !important;
    }
}


.instructions>p{
    margin: 15px;
    padding: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.warning-instruction{
    color:#b80000;
    font-weight: bold;
}

#inst-heading{
    color:#212221;
    font-size: 18px;
    display: flex;
    justify-content: center;
    font-weight: 700;
}

#gen-instructions{
    display: block;
    margin: 10px;
    padding: 2rem;
    max-width: 1050px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    
}

.gen-heading{
    color: #183a1d;
}

#gen-instructions>p{
    margin: 8px;
    padding: 8px;
}

#next-btn{
    display: flex;
    justify-content: space-evenly;
}

#cat-heading{
    display: flex;
    justify-content: center;
    margin:20px;
    color:#212221;
    margin-top:2rem;
}

#marks-div{
    display: flex;
    flex-direction: row;
    font-weight: bold;
}

#time-div{
    display: flex;
    flex-direction: row;
    font-weight: bold;
    justify-content: flex-end;
}

.marks-time{
    padding:5px;
    color:#b80000;
}

.test-btn{
    background-color: #f0a04b;
    border-color: #f0a04b;
    color:#183a1d;
    margin-bottom: 2rem;
    margin-top: 1rem;
    
}

.test-btn:hover {
    background-color: #f0a04b;
    border-color: #f0a04b;
    color:#071f0b;
}

.test-btn:focus{
    color: #183a1d;
    background-color: #f0a04b;
    border-color: #f0a04b;
    
}

.head-div{
    text-align: left;
    background-color: #e1eedd;
    /* padding: 10px; */
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
}

#timer{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 10px;
}

.time-left{
    background: #183a1d;
    padding:1px;
    color: #fff;
    width:30px;
}

.timer-space{
    font-size:1.25rem;
    font-weight: 800;
}

#symbol-status{
    display: grid;
    justify-content: center;
    padding: 10px;
    margin-top: 0.5rem;
}

.symbols>li{
    list-style: none;
    padding-bottom: 10px;
    display: inline-block;
}

.symbol-text{
    margin-top: 4px;
    margin-left: 5px;
}

#test-ins-div{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    padding:1rem;
}

.ins-btn{
    pointer-events: none;
}






.btn-group, .btn-group-vertical {
    display: block;
    box-shadow: none;
    
}

.btn-group:hover, .btn-group-vertical:hover {
    display: block;
    box-shadow: none;
    
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 3px;
    padding: 10px;
}

.nav-btn{
    padding: 10px;
    margin: 3px;
    width: 38px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

#submit-div{
    display: flex;
    justify-content: center;
    border-top: 1px solid #000;
    padding: 10px;
    margin-top: 2rem;
}

.nav-btn-checked{
    padding: 10px;
    margin: 3px;
    width: 38px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    background-color: #73a24e;
    border-color: #73a24e;
    color:#fff;
}

.nav-btn-current{
    padding: 10px;
    margin: 3px;
    width: 38px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    background-color: #b3b3b3;
    border-color: #b3b3b3;
    
}

.nav-btn-visited{
    padding: 10px;
    margin: 3px;
    width: 38px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    background-color: #d03f2f;
    border-color: #d03f2f;
    color:#fff; 
    
}

.indicator-btn{
    padding:2px;
    pointer-events: none;
    margin-left: 3px;
    margin-top: 5px;
}


.sub-btn{  
        background-color: #2b6777;
        border-color: #2b6777;
}

.sub-btn:hover,.sub-btn:active, .sub-btn:focus {
    background-color: #2b6777;
    border-color: #2b6777;
    box-shadow: 0 0 0 .25rem #e1eedd;
}

.btn-check:active + .sub-btn:focus, .btn-check:checked + .sub-btn:focus, 
.sub-btn.active:focus, .sub-btn:active:focus, .show > 
.sub-btn.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem #c8d8e4;
}

.test-summary{
    border-bottom: 1px solid #000;
    margin-top:0.2rem;
    margin-bottom: 0.2rem;
    padding-left: 0.2rem;
    font-size: 12px;
    font-weight: 600;
}

.indicator-text{
    margin-top: 0.9rem;
    margin-left: 5px;
}

.test-summary-list{
    list-style: none;
    margin-bottom:0.5rem;
}

@media only screen and (max-device-width: 480px) {
    .test-summary-list{
       display:inline-flex;
    }
}

.modal-title{
    text-align: center;
}
#mainconsole{
    display: flex;
}

#left-console{
    background: #c8d8e4;
    margin: 3px auto;
}

#middle-console{
    padding:15px;
}

#option-column{
    width:9%;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
}

.table > :not(:first-child) {
    border-top: 1px solid currentColor;
}

#console-div{
    min-height:100%;
   position:relative;
}

#ques-div{
    height: 100%;
    padding-bottom: 60px;
    min-height: 500px;
}


#next-prev{
   position:relative;
   bottom:0;
   width:100%;
   height:60px;
   margin-top:10px;
}

#next{
    display: flex;
    justify-content: flex-end;
}

#prev{
    display: flex;
    justify-content: flex-start;
}

#clear-res{
    display: flex;
    justify-content: flex-end;
}

.table > :not(caption) > * > * {
    padding: 0.7rem .7rem;
}

.ans-radio{
    width:50%;
    height:1.5rem;
    border:0;
}

.img-column{
    display: flex;
    justify-content: center;
}

.questionpre {
    padding: 0;
    font-size: 16px;
    line-height: 1.4;
    word-break: initial;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: justify;
    color: #222;
    background-color: transparent;
    background-color: initial;
    border: none;
    border-radius: 0;
    font-weight: 600;
}

table {
    caption-side: bottom;
    border-collapse: separate;
    border-collapse: initial;
}

.ques-nav-btn{
    color: #000;
    background-color: #f0a04b;
    border-color: #f0a04b;
}

.ques-nav-btn:hover,.ques-nav-btn:active, .ques-nav-btn:focus {
    background-color: #f0a04b;
    border-color: #f0a04b;
    box-shadow: 0 0 0 .25rem #e1eedd;
    color:#000 !important;
}

btn-check:active + .ques-nav-btn:focus, .btn-check:checked + 
.ques-nav-btn:focus, .ques-nav-btn.active:focus, 
.ques-nav-btn:active:focus, .show > .ques-nav-btn.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgb(240, 160, 75);
}

body{
    font-family: 'Inter';
}

.option-row{
    font-size: 16px;
    font-weight: 500;
}
.performance-card{
    display: flex;
    background:#f2f2f2;
    border-radius: 4px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    justify-content:space-evenly;
    /* padding: 1.2rem; */
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}

.performance-card-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-icons{
    display: flex;
}

.result-block{
    /* padding: 1rem; */
    margin-top: 0.5rem;
}

.btn-container{
    margin-bottom:3rem;
}

.card-icon{
    border-radius: 50%;
    padding:4px;
    
}

.icon-img{
    width: 40px;
    height: 40px;
    padding:3px;
}

.scorecard-text{
    /* margin: 3px 0px 0px 15px; */
    font-size: 14px;
}

.scorecard-text>p{
    display: flex;
    flex-direction: initial;
    margin-bottom: 0px;
    margin-top: 5px;
    margin-left: 1rem;
    justify-content: space-around;

}

#score-icon{
    background: #0ece6d;
}

#rank-icon{
    background: #e4174f;
}

#attempted-icon{
    background: #8942e5;
}

#accuracy-icon{
    background: #0ad0f4;
}

#percentile-icon{
    background: #6c62f9;
}

.result-table{
    text-align: center;
    vertical-align: middle;
    font-size:14px;
    font-weight: 700;
}

.progress-bar{
    width: 75%;
    height: 3rem;
    
}

.total-ques{
    padding:0 !important;
    margin:0;
    background: #f8f8ff !important;
    border-left-width: 1px;
}

.attempt-ques{
    padding:0 !important;
    margin:0;
    background: #f8f8ff !important;
    border-left-width: 1px;
    box-shadow: none !important;
   
}

.score-ques{
    padding:0 !important;
    margin:0;
    background: #f8f8ff !important;
    border-right-width: 1px;
    border-left-width: 1px;
    box-shadow: none !important;
   
}

.total-progress{
    background: #8942e5;
}

.attempt-progress{
    background: #39395f;
}

.score-progress{
    background: #2b6777;
}

.ques-tooltip{
    margin-left:10px;
    margin-right:10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    background: #6c757d;
    color: #fff;
}

.ques-tooltip-correct{
    margin-left:10px;
    margin-right:10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    background: #00b300;
    color: #fff;
}

.btn:hover{
    color:#fff;
}

.ques-tooltip-wrong{
    margin-left:10px;
    margin-right:10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    background:#990000;
    color: #fff;
}


.question-review{
    display: flex;
    color: #fff !important;
    background: #f8f8ff !important;
    flex-wrap: wrap;
    padding:5px;
}

.legend-holder{
    display: inline-grid;
    padding: 10px;
    margin-left: 3%;
    font-weight: 400;
    justify-content: center;
}

.legend-demo{
    width: 25px;
    height: 25px;
    margin-left: 5%;
    cursor:unset !important;
    margin-top: 0.5rem;
}

.ques-tooltip-check:focus, .ques-tooltip:focus {
    outline: 0;
    box-shadow: none;
}

.ques-tooltip-correct-check:focus, .ques-tooltip-correct:focus {
    outline: 0;
    box-shadow: none;
}

.ques-tooltip-wrong:focus, .ques-tooltip-wrong:focus {
    outline: 0;
    box-shadow: none;
}

.tooltip-ques{
    color: #fff !important;
}

.summary-report{
    font-weight: 400;
    font-family: sans-serif;
}

.tooltip-div{
    margin:10px;
}

.seven{
    margin-top:2rem;
}

.seven h1 {
        text-align: center;
        font-size:20px; font-weight:400; color:#222; letter-spacing:1px;
        text-transform: uppercase;
        display: grid;
        /* grid-template-columns: 1fr max-content 1fr; */
        /* grid-template-rows: 27px 0; */
        grid-gap: 20px;
        align-items: center;
    }
    
    .seven h1:after,.seven h1:before {
        content: " ";
        display: block;
        border-bottom: 1px solid #c50000;
        border-top: 1px solid #c50000;
        height: 3px;
        background-color:#f8f8f8;
    }

    body{
        font-family: 'Inter';
        font-weight: 700;
    }

    html, body {width: auto!important; overflow-x: hidden!important} 

#ques-div-sol{
    height: 100%;
    padding-bottom: 60px;
    
}

.checkmark {
    display:inline-block;
    width: 22px;
    height:22px; /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    margin-left: 15px;
    vertical-align: inherit;
}


.checkmark_circle {
    position: absolute;
    width:22px;
    height:22px;
    background-color: green;
    border-radius:11px;
    left:0;
    top:0;
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color:#fff;
    left:11px;
    top:6px;
}

.checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:#fff;
    left:8px;
    top:12px;
}

.sol-container{
    margin-bottom: 1rem;
}

@media all and (min-width: 480px) {
    .Login {
      padding: 10px 0;
      
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
  .BoxContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    
  }

  .FormContainer{
    padding: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 2.5px rgba(15, 15, 15, 0.19);
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    background-color: #e1eedd;
    margin-bottom: 2%;

  }

  .login-heading{
    margin-top:2em;
    color: #CC5500;
    font-family: sans-serif;
  }

  .login-btn-div{
    margin-top:20px;
  }

  .login-btn{
    background: #183a1d;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .login-btn:hover,.login-btn:active,.login-btn:focus{
    background: #183a1d;
    color: rgb(177, 175, 175);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:5px;
    padding:5px;
  }

  .form-control{
    width: 300px;
  }



.pass-wrapper {
  position: relative;
  margin-bottom: 14px;
}

i {
  position: absolute;
  top: 29%;
  right: 31%;
}
i:hover {
  color: #183a1d;
  cursor: pointer;
}

#inputPassword3{
  margin-top:20px;
}

.email-error{
  margin-top:0px;
}


.form-control.is-invalid{
  margin-bottom:5px;
}

.fa{
  font-size: 17px;
}

.invalid-user{
  color: #dc3545;
  font-size:16px;
  font-weight: 700;
  margin:5px;
  text-align: center;
}

.invalid-feedback {
  width:auto;
  width:initial;
}

.signup-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:12px;
  padding:5px;
}
  
.signup-label{
  color: #C50;
  font-size: 12px;
}

.login-text{
  color: #183a1d;
}

.activation-msg{
  margin-top: 2rem;
  color: #C50;
  font-size: 16px;
}

.sendmail{
  color: #183a1d;
  font-size: 16px;
}

.fa-eye{
  top: 50%;
  right: 5%;
}
.progress-table{
    text-align: center;
}

.progress-table-body{
    font-weight: 650 !important;
    font-family: sans-serif;
}

.table-dark {
    --bs-table-bg: #C50;
    border-color: #C50;
}

.progress-text{
    text-align: center;
    color:brown;
    font-weight: 600;
}

.progress-heading{
    display: flex;
    justify-content: center;
}

.section_padding_130 {
    padding-top: 130px;
    padding-bottom: 130px;
}
.faq_area {
    position: relative;
    z-index: 1;
    background-color: #f5f5ff;
}

.faq-accordian {
    position: relative;
    z-index: 1;
}
.faq-accordian .card {
    position: relative;
    z-index: 1;
    margin-bottom: 1.5rem;
}
.faq-accordian .card:last-child {
    margin-bottom: 0;
}
.faq-accordian .card .card-header {
    background-color: #ffffff;
    padding: 0;
    border-bottom-color: #ebebeb;
}
.faq-accordian .card .card-header h6 {
    cursor: pointer;
    padding: 1.75rem 2rem;
    color: #3f43fd;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    justify-content: space-between;
}
.faq-accordian .card .card-header h6 span {
    font-size: 1.5rem;
}
.faq-accordian .card .card-header h6.collapsed {
    color: #070a57;
}
.faq-accordian .card .card-header h6.collapsed span {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}
.faq-accordian .card .card-body {
    padding: 1.75rem 2rem;
}
.faq-accordian .card .card-body p:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
    .support-button p {
        font-size: 14px;
    }
}

.support-button i {
    color: #3f43fd;
    font-size: 1.25rem;
}
@media only screen and (max-width: 575px) {
    .support-button i {
        font-size: 1rem;
    }
}

.support-button a {
    text-transform: capitalize;
    color: #2ecc71;
}
@media only screen and (max-width: 575px) {
    .support-button a {
        font-size: 13px;
    }
}

#first-faq{
    visibility: visible; 
    -webkit-animation-delay: 0.2s; 
            animation-delay: 0.2s; 
    -webkit-animation-name: fadeInUp; 
            animation-name: fadeInUp;
}

.accordion-item:last-of-type .accordion-collapse {
    background: #e1eedd;
  }

  #contactmail{
      color: #2ecc71
  }

  .faq_area{
      padding-top:3rem;
      padding-bottom:  3rem;
      background: #f5f7fa ;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #f5f7fa , #c3cfe2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }
