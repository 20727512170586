/* custom bootstrap section */

.navbar{
    background-color: #183a1d !important;
    padding-top: 0;
}

.navbar-light .navbar-nav .nav-link {
    color: #e1eedd;
}

.navbar-light .navbar-brand {
    color: #e1eedd;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #f0a04b;
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: #f0a04b;
}

.login-btn{
    margin-right: 20px;
    text-decoration: none;
    background: none;
    border:none;
    font-weight: 400;
    font-size: 1.2em;
    color:#CC5500;
}

.login-btn:hover{
    
    margin-right: 20px;
    text-decoration: none;
    background: none;
    border:none;
    font-weight: 400;
    font-size: 1.2em;
    color:#ff791a;
    transition: 200ms linear; 
}

.signup-btn{
    background: #CC5500;
    border:none;
    color:#e1eedd;
    padding:5px;
    font-weight: 400;
    font-size: 1.2em;
}

.signup-btn:hover {
    background: #CC5500;
    border:none;
    color:#b4d4aa;
    padding:5px;
    font-weight: 400;
    font-size: 1.2em;
    transition: 200ms linear;
    box-shadow: none;
}

.username{
    padding-left: .5rem;
    margin-right: 1rem;
    color:#CC5500;
   
    font-weight: 700;
}

.wctext{
    color:#CC5500;
    
}

.numberCircle {
    border-radius: 50%;
    width: 35px;
    height: 30px;
    padding: 2px;
    background: #e1eedd;
    border: 2px solid #666;
    color: #C50;
    text-align: center;
    font-weight: 700;
    display: table;
    margin-top: 0.1rem;
}

.dropdown-menu{
    background: #CC5500;
    color:#fff;
}

.dropdown-item{
    color:#e1eedd;
}

.dropdown-item:hover, .dropdown-item:focus, .dropdown-item:active{
    color:#cbd1ca;
    background-color: #CC5500 !important;
}

.nav-item{
    margin-right: 1rem;
}

.welcome-div{
    display: flex;
    margin-top:0.5rem;
}

#navbarDropdownMenuLink{
    display: flex;
}

.navbar-brand {
    padding-top: 1rem;
  }

  .navbar-light .navbar-toggler {
    color: #183a1d !important;
    border-color: none;
  }

  /* .navbar{
    font-size: 30px;
    font-size: 2.5vw !important;
  } */

  .navbar-nav{
    margin-top: 0.4rem;
  }

