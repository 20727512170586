.subcat-desc{
    width: 100%;
    max-width: 1320px;
    /* padding:2rem; */
    margin-top:1.5rem;
}

.subcat-heading{
    color: #183a1d; 
}

.subcat-text{
    box-shadow: rgba(10, 32, 18, 0.4) 0px 0px 0px 2px, rgba(11, 46, 20, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    margin-top:1rem;
}

/* === HEADING STYLE #1 === */
.one h1 {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
  }
  .one h1:before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: #b80000;
  }
  .one h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 8px;
    left: 50%;
    margin-left: -50px;
    background-color: #b80000;
  }
  

  /* === BASE HEADING === */ 

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 30px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  h1 span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
  }
  h1 em {
    font-style: normal;
    font-weight: 600;
  }