@media all and (min-width: 480px) {
    .Login {
      padding: 10px 0;
      
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
  .BoxContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    
  }

  .FormContainer{
    padding: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 2.5px rgba(15, 15, 15, 0.19);
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    display: flex;
    flex-direction: column;
    -moz-box-align: center;
    align-items: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    background-color: #e1eedd;
    margin-bottom: 2%;

  }

  .login-heading{
    margin-top:2em;
    color: #CC5500;
    font-family: sans-serif;
  }

  .login-btn-div{
    margin-top:20px;
  }

  .login-btn{
    background: #183a1d;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .login-btn:hover,.login-btn:active,.login-btn:focus{
    background: #183a1d;
    color: rgb(177, 175, 175);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:5px;
    padding:5px;
  }

  .form-control{
    width: 300px;
  }



.pass-wrapper {
  position: relative;
  margin-bottom: 14px;
}

i {
  position: absolute;
  top: 29%;
  right: 31%;
}
i:hover {
  color: #183a1d;
  cursor: pointer;
}

#inputPassword3{
  margin-top:20px;
}

.email-error{
  margin-top:0px;
}


.form-control.is-invalid{
  margin-bottom:5px;
}

.fa{
  font-size: 17px;
}

.invalid-user{
  color: #dc3545;
  font-size:16px;
  font-weight: 700;
  margin:5px;
  text-align: center;
}

.invalid-feedback {
  width:initial;
}

.signup-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:12px;
  padding:5px;
}
  
.signup-label{
  color: #C50;
  font-size: 12px;
}

.login-text{
  color: #183a1d;
}

.activation-msg{
  margin-top: 2rem;
  color: #C50;
  font-size: 16px;
}

.sendmail{
  color: #183a1d;
  font-size: 16px;
}

.fa-eye{
  top: 50%;
  right: 5%;
}