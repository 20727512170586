@import url('https://fonts.googleapis.com/css?family=Inter');

#mainconsole{
    display: flex;
}

#left-console{
    background: #c8d8e4;
    margin: 3px auto;
}

#middle-console{
    padding:15px;
}

#option-column{
    width:9%;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
}

.table > :not(:first-child) {
    border-top: 1px solid currentColor;
}

#console-div{
    min-height:100%;
   position:relative;
}

#ques-div{
    height: 100%;
    padding-bottom: 60px;
    min-height: 500px;
}


#next-prev{
   position:relative;
   bottom:0;
   width:100%;
   height:60px;
   margin-top:10px;
}

#next{
    display: flex;
    justify-content: flex-end;
}

#prev{
    display: flex;
    justify-content: flex-start;
}

#clear-res{
    display: flex;
    justify-content: flex-end;
}

.table > :not(caption) > * > * {
    padding: 0.7rem .7rem;
}

.ans-radio{
    width:50%;
    height:1.5rem;
    border:0;
}

.img-column{
    display: flex;
    justify-content: center;
}

.questionpre {
    padding: 0;
    font-size: 16px;
    line-height: 1.4;
    word-break: initial;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: justify;
    color: #222;
    background-color: initial;
    border: none;
    border-radius: 0;
    font-weight: 600;
}

table {
    caption-side: bottom;
    border-collapse: initial;
}

.ques-nav-btn{
    color: #000;
    background-color: #f0a04b;
    border-color: #f0a04b;
}

.ques-nav-btn:hover,.ques-nav-btn:active, .ques-nav-btn:focus {
    background-color: #f0a04b;
    border-color: #f0a04b;
    box-shadow: 0 0 0 .25rem #e1eedd;
    color:#000 !important;
}

btn-check:active + .ques-nav-btn:focus, .btn-check:checked + 
.ques-nav-btn:focus, .ques-nav-btn.active:focus, 
.ques-nav-btn:active:focus, .show > .ques-nav-btn.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgb(240, 160, 75);
}

body{
    font-family: 'Inter';
}

.option-row{
    font-size: 16px;
    font-weight: 500;
}