.test-heading{
    padding: 10px;
    margin: 0 auto;
    display: flex;
    
}

@media only screen and (max-device-width: 480px) {
    .heading-container{
        border-bottom: 1px solid #183a1d;
    }
}

.mark-time-container{
    display: flex;
   
}

@media only screen and (max-device-width: 480px) {
    .mark-time-container{
        padding: 5px !important;
    }
}


.instructions>p{
    margin: 15px;
    padding: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.warning-instruction{
    color:#b80000;
    font-weight: bold;
}

#inst-heading{
    color:#212221;
    font-size: 18px;
    display: flex;
    justify-content: center;
    font-weight: 700;
}

#gen-instructions{
    display: block;
    margin: 10px;
    padding: 2rem;
    max-width: 1050px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    
}

.gen-heading{
    color: #183a1d;
}

#gen-instructions>p{
    margin: 8px;
    padding: 8px;
}

#next-btn{
    display: flex;
    justify-content: space-evenly;
}

#cat-heading{
    display: flex;
    justify-content: center;
    margin:20px;
    color:#212221;
    margin-top:2rem;
}

#marks-div{
    display: flex;
    flex-direction: row;
    font-weight: bold;
}

#time-div{
    display: flex;
    flex-direction: row;
    font-weight: bold;
    justify-content: flex-end;
}

.marks-time{
    padding:5px;
    color:#b80000;
}

.test-btn{
    background-color: #f0a04b;
    border-color: #f0a04b;
    color:#183a1d;
    margin-bottom: 2rem;
    margin-top: 1rem;
    
}

.test-btn:hover {
    background-color: #f0a04b;
    border-color: #f0a04b;
    color:#071f0b;
}

.test-btn:focus{
    color: #183a1d;
    background-color: #f0a04b;
    border-color: #f0a04b;
    
}

.head-div{
    text-align: left;
    background-color: #e1eedd;
    /* padding: 10px; */
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
}

#timer{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 10px;
}

.time-left{
    background: #183a1d;
    padding:1px;
    color: #fff;
    width:30px;
}

.timer-space{
    font-size:1.25rem;
    font-weight: 800;
}

#symbol-status{
    display: grid;
    justify-content: center;
    padding: 10px;
    margin-top: 0.5rem;
}

.symbols>li{
    list-style: none;
    padding-bottom: 10px;
    display: inline-block;
}

.symbol-text{
    margin-top: 4px;
    margin-left: 5px;
}

#test-ins-div{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    padding:1rem;
}

.ins-btn{
    pointer-events: none;
}





