@import url('https://fonts.googleapis.com/css?family=Inter');

.performance-card{
    display: flex;
    background:#f2f2f2;
    border-radius: 4px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    justify-content:space-evenly;
    /* padding: 1.2rem; */
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}

.performance-card-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-icons{
    display: flex;
}

.result-block{
    /* padding: 1rem; */
    margin-top: 0.5rem;
}

.btn-container{
    margin-bottom:3rem;
}

.card-icon{
    border-radius: 50%;
    padding:4px;
    
}

.icon-img{
    width: 40px;
    height: 40px;
    padding:3px;
}

.scorecard-text{
    /* margin: 3px 0px 0px 15px; */
    font-size: 14px;
}

.scorecard-text>p{
    display: flex;
    flex-direction: initial;
    margin-bottom: 0px;
    margin-top: 5px;
    margin-left: 1rem;
    justify-content: space-around;

}

#score-icon{
    background: #0ece6d;
}

#rank-icon{
    background: #e4174f;
}

#attempted-icon{
    background: #8942e5;
}

#accuracy-icon{
    background: #0ad0f4;
}

#percentile-icon{
    background: #6c62f9;
}

.result-table{
    text-align: center;
    vertical-align: middle;
    font-size:14px;
    font-weight: 700;
}

.progress-bar{
    width: 75%;
    height: 3rem;
    
}

.total-ques{
    padding:0 !important;
    margin:0;
    background: #f8f8ff !important;
    border-left-width: 1px;
}

.attempt-ques{
    padding:0 !important;
    margin:0;
    background: #f8f8ff !important;
    border-left-width: 1px;
    box-shadow: none !important;
   
}

.score-ques{
    padding:0 !important;
    margin:0;
    background: #f8f8ff !important;
    border-right-width: 1px;
    border-left-width: 1px;
    box-shadow: none !important;
   
}

.total-progress{
    background: #8942e5;
}

.attempt-progress{
    background: #39395f;
}

.score-progress{
    background: #2b6777;
}

.ques-tooltip{
    margin-left:10px;
    margin-right:10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    background: #6c757d;
    color: #fff;
}

.ques-tooltip-correct{
    margin-left:10px;
    margin-right:10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    background: #00b300;
    color: #fff;
}

.btn:hover{
    color:#fff;
}

.ques-tooltip-wrong{
    margin-left:10px;
    margin-right:10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    background:#990000;
    color: #fff;
}


.question-review{
    display: flex;
    color: #fff !important;
    background: #f8f8ff !important;
    flex-wrap: wrap;
    padding:5px;
}

.legend-holder{
    display: inline-grid;
    padding: 10px;
    margin-left: 3%;
    font-weight: 400;
    justify-content: center;
}

.legend-demo{
    width: 25px;
    height: 25px;
    margin-left: 5%;
    cursor:unset !important;
    margin-top: 0.5rem;
}

.ques-tooltip-check:focus, .ques-tooltip:focus {
    outline: 0;
    box-shadow: none;
}

.ques-tooltip-correct-check:focus, .ques-tooltip-correct:focus {
    outline: 0;
    box-shadow: none;
}

.ques-tooltip-wrong:focus, .ques-tooltip-wrong:focus {
    outline: 0;
    box-shadow: none;
}

.tooltip-ques{
    color: #fff !important;
}

.summary-report{
    font-weight: 400;
    font-family: sans-serif;
}

.tooltip-div{
    margin:10px;
}

.seven{
    margin-top:2rem;
}

.seven h1 {
        text-align: center;
        font-size:20px; font-weight:400; color:#222; letter-spacing:1px;
        text-transform: uppercase;
        display: grid;
        /* grid-template-columns: 1fr max-content 1fr; */
        /* grid-template-rows: 27px 0; */
        grid-gap: 20px;
        align-items: center;
    }
    
    .seven h1:after,.seven h1:before {
        content: " ";
        display: block;
        border-bottom: 1px solid #c50000;
        border-top: 1px solid #c50000;
        height: 3px;
        background-color:#f8f8f8;
    }

    body{
        font-family: 'Inter';
        font-weight: 700;
    }

    html, body {width: auto!important; overflow-x: hidden!important} 
