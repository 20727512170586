.mainContainer{
    width:100%;
    /* height: 450px; */
    background-image: url(../images/quizbg.jpg);
    background-size: cover;
    background-position: 0px -150px;
}

.bgFilter{
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    background-color: rgba(225, 238, 221, 0.85)
}

.UpperLayer{
    margin-top:2rem;
    text-align: center;
}

.DescText {
    color: #183a1d;
    font-size: 16px;
    font-weight: 400;
}

.Features{
flex: 0 0 auto;
/* width: 100%; */
margin-top: 20px;
display: flex;
flex-direction: column;
font-weight: bold;
color: #CC5500;
}

.Catbtn{
    padding: 15px;
    margin: 5px;
    border-radius: 50%;
    pointer-events: none;
    background-color: #f0a04b;
    border-color: #183a1d;
    color: #183a1d;
}


.InnerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: initial;
}

.LogoContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 150px;
    height: 100px;
    margin: 2rem;
}

.SloganText {
    font-weight: 700;
    font-size: 28px;
    color: #C50;
}