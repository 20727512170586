
.flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/*  custom bootstrap section */
.catcard {
    box-shadow: rgba(225, 238, 221, 1) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    margin: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    padding:0.5rem;  
}
.catcard:hover {
cursor:unset;
}

.subcat-btn{  
width: 50%;
margin: 0 auto;
display: flex;
text-align: center;
justify-content: center;
margin-top:10px;  
}

.subcatcard-text{
  margin-top:10px !important;
}
